import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

const actYear = new Date().getFullYear();
const exp = (actYear - 2006);

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: true,
    fallbackLng: 'es',
    interpolation: {
      escapeValue: false,
    },
    resources: {
      es: {
        translation: {
          lang: {
            ISO_639_1: 'es',
            ISO_639_2: 'spa'
          },
          nav: {
              about: 'Sobre mi',
              curriculum: 'Curriculum',
              experience: 'Experiencia',
              education: 'Educación',
              knowledge: 'Conocimiento',
              skills: 'Habilidades',
              languages: 'Lenguajes',
              contact: 'Contacto',
              pdf_download: 'Descargar curriculum como PDF'
          },
          nav_link: {
            about: 'quien-soy',
            curriculum: 'curriculum',
            experience: 'experiencia',
            education: 'educacion',
            knowledge: 'conocimiento',
            skills: 'habilidades',
            languages: 'lenguajes',
            contact: 'contacto'
          },
          about: {
            type: 'quien-soy',
            title: 'Desarrollo Web...',
            title2: 'Programación & Diseño.',
            content: `<p>Con más de ${exp} años de dedicación en el campo del desarrollo y diseño web, me he consolidado como un profesional autodidacta y apasionado. Mi especialización radica en la creación de sistemas de información complejos con interfaces intuitivas que priorizan la experiencia del usuario.</p>
            <p>Mi trayectoria comenzó tras culminar mis estudios en la Licenciatura en Diseño Integral de la Universidad Jesuita ITESO en Guadalajara, México, en 2006. Desde entonces, he mantenido un firme compromiso con el perfeccionamiento continuo, adaptándome a las nuevas tecnologías y tendencias del mercado. He adquirido habilidades en una amplia gama de tecnologías, desde las animaciones en Flash hasta los frameworks más vanguardistas como React y Redux.</p>
            <p>Mi pasión por brindar soluciones excepcionales se ve complementada por mi compromiso con la seguridad en cada proyecto. Mi objetivo es garantizar la satisfacción de mis clientes al ofrecer soluciones web estéticamente atractivas, robustas y seguras. Mi enfoque meticuloso en cada detalle y mi capacidad para ofrecer resultados de calidad son los pilares de mi trabajo profesional.</p>`
          },
          technologies: {
            phrase: 'A lo largo de los años, he acumulado experiencia<br /> en multiples tecnologías web.'
          },
          curriculum: {
            work_experience: {
              title: 'EXPERIENCIA LABORAL',
              type: 'experiencia',
              present: 'presente',
              hexaware: {
                title: 'DESARROLLADOR MICROSOFT FULL STACK',
                at: 'en Hexaware Technologies / EY',
                location: 'Guadalajara / Remoto',
                content: `[Detalles bajo Acuerdo de No Divulgación]`
              },
              hcl: {
                title: 'DESARROLLADOR SENIOR',
                at: 'en HCL Technologies / AllState - Esurance',
                location: 'San Francisco & Zapopan',
                content: `Desarrollador Web, responsable de maquetación, desarrollo y estilismo en la primer fase del proyecto. 
                Desarrollo de componentes React / Redux, mantenimiento de Node privado. paquete js; 
                Desarrollé estilos para componentes en el lenguaje de hojas de estilo SASS durante un año y 
                medio, luego cambié a otro producto donde desarrollo componentes y estilos para la versión de 
                cambio de marca de una aplicación web .NET MVC Web App.
                A partir de 2019, hubo re-estructuración del equipo y me cambiaron al equipo de desarrollo 
                la aplicación web ESURANCE, en el equipo Customer Flow de ESURANCE / AllState,  donde mantuve Frontend y Backend en .NET.
                Como desarrollador "Full Stack", fuí responsable de nuevas mejoras, mantenimiento, resolución de problemas y soporte para aplicaciones del Área de Ventas / Mercadeo.
                Responsable de lanzamientos a producción y defectos de sitio en vivo, levantados en la aplicación JIRA.
                Colaboré en el cambio de marca para el proyecto de CF para las cotizaciones para Vehículos.
                Manejé la implementación de diseño responsivo para páginas antiguas de CF. Trabajé en la mejora de rendimiento para "Customer Flow", salvando en promedio 1.5 segundos de descarga.
                Analicé interacciones fallidas y actividad maliciosa en la aplicación web en el entorno de producción usando Splunk y HotJar,
                proporcionando información e informes, ayudando a nuestro equipo a reaccionar a tiempo ante esas amenazas.`
              },
              puntoasterisco: {
                title: 'DESARROLLADOR WEB',
                at: 'en PUNTOASTERISCO S.C.',
                location: 'Chicago, Washington, Guadalajara & Zapopan',
                content: `Desarrollador Web Full Stack, responsable del diseño, desarrollo y actualización de 
                páginas web dinámicas, desarrolladas en PHP, HTML 5, CSS 3, JavaScript, con Frameworks como 
                Yii2, Foundation, Bootstrap, jQuery, jQuery-UI, Ink y bibliotecas externas como Font Awesome, 
                CSS Animate y hojas de estilo preprocesadas en SASS (SCSS), Desarrollo de CMS en PHP. 
                Soporte Técnico para Cuentas de Correo por teléfono (Español). 
                Gerente de un desarrollador web frontend subordinado.`
              },
              ggc: {
                title: 'WEB MASTER & DISEÑADOR MULTIMEDIA',
                at: 'en Grupo Gráfico Consultor S.C.',
                location: 'Guadalajara',
                content: `Actualización diaria y mensual de las páginas web: rediseno.com, bazargrafico.com, 
                imprentamexico.com, design-fest.com y dpixeles.com. Presentación de marca y producto. 
                Presentación de Media Kit y diversos eventos. 
                Director de Producción Web y Multimedia en congresos internacionales Design Fest 2007. 
                Manejo de equipos Audiovisuales en la sala de conferencias del TEC de Monterrey Campus Guadalajara. 
                Diapositivas de diseño y animación. Diseño de animación y presentación de ponentes y expositores. 
                Diseño web y coordinación del registro de actualizaciones diarias por web. Diseño web, Banners y Pop-ups.`
              },
              freelance: {
                title: 'DISEÑADOR & DESARROLLADOR WEB',
                at: 'Freelance',
                location: 'San Francisco/remoto, Bombay/remoto, Los Mochis, Guadalajara & Zapopan',
                content: `Desarrollé y mantuve sitios web personalizados para varios clientes en diferentes industrias.
                Creé diseños web visualmente atractivos adaptados a la marca y especificaciones de cada cliente.
                Implementé principios de diseño responsivo para garantizar una experiencia de usuario óptima en todos los dispositivos.
                Realicé mantenimiento regular y actualizaciones de sitios web para mantenerlos seguros y actualizados.
                Proporcioné soluciones multimedia, incluidas animaciones Flash y servicios de edición de video, al igual que imagen corporativa y diseño editorial.`
              }
            },
            education: {
              title: 'EDUCACIÓN',
              type: 'educacion',
              iteso_design: {
                title: 'LIC. DISEÑO INTEGRAL',
                at: 'en ITESO Jesuit University',
                location: 'Tlaquepaque',
                content: `Licenciatura en Diseño Gráfico e Industrial.`,
                license: `Cédula Profesional: 5880448`
              },
              ivei_web_master: {
                title: 'DIPLOMADO EN WEB MASTER',
                at: 'at IVEI',
                location: 'Guadalajara',
                content: `Curso básico de un año, aprendiendo HTML5, CSS3, JavaScript, PHP, JAVA, .NET, MySQL`
              },
              google_analytics: {
                title: 'CERTIFICACIÓN DE GOOGLE ANALYTICS (CADUCO)',
                at: 'en Google Academy',
                location: 'Online',
                validity: `Caducidad: 20 July 2015 - 20 October 2016`,
                license: `Cédula: 109277918343153033229`
              },
              itszapopan_java: {
                title: 'CURSO DE JAVA',
                at: 'en ITSZapopan',
                location: 'Zapopan',
                content: `Becado por Gobierno de Zapopan, a través del ICOE en su programa
                "Aquí hay Futuro" para el curso de JAVA, con una duración de 3 meses.`
              },
              uncommon_concept_react: {
                title: 'CURSO DE REACT / REDUX',
                at: 'por Uncommon Concept',
                location: 'Zapopan',
                content: `Formación de 3 meses impartida a todo el Equipo de Proyecto de Esurance en HCL Technologies,
                para un nuevo proyecto que requería esta tecnología.`
              }
            },
            knowledge: {
              title: 'CONOCIMIENTO',
              type: 'conocimiento'
            },
            contact: {
              title: 'CONTACTO',
              type: 'contacto'
            },
            skills: {
              title: 'HABILIDADES',
              type: 'habilidades'
            },
            languages: {
              title: 'LENGUAJES',
              type: 'idiomas',
              spanish: 'Español (Materno)',
              english: 'Inglés',
              asterisc1: 'No son considerados lenguajes de programación, pero si lenguajes de maquetado.',
              asterisc2: 'Considerados lenguajes para scripting.'
            }
          },
          mes: {
            1: 'Ene',
            2:'Feb',
            3:'Mar',
            4:'Abr',
            5:'May',
            6:'Jun',
            7:'Jul',
            8:'Ago',
            9:'Sept',
            10:'Oct',
            11:'Nov',
            12:'Dic',
          }
        }
      },
      en: {
        translation: {
          lang: {
            ISO_639_1: 'en',
            ISO_639_2: 'eng'
          },
          nav: {
            about: 'About me',
            experience: 'Experience',
            education: 'Education',
            knowledge: 'Knowledge',
            skills: 'Skills',
            languages: 'Languages',
            contact: 'Contact',
            pdf_download: 'Download curriculum as PDF'
          },
          nav_link: {
            about: 'about',
            experience: 'experience',
            education: 'education',
            knowledge: 'knowledge',
            skills: 'skills',
            languages: 'languages',
            contact: 'contact'
          },
          about: {
            type:'about',
            title: 'Web Development...',
            title2: 'Coding & Design.',
            content: `<p>With over ${exp} years of dedication in the field of web development and design, I have established myself as a self-taught and passionate professional. My specialization lies in creating complex information systems with intuitive interfaces that prioritize user experience.</p>
            <p>My journey began after completing my studies in Integral Design at the Jesuit University ITESO in Guadalajara, Mexico, in 2006. Since then, I have maintained a strong commitment to continuous improvement, adapting to new technologies and market trends. I have acquired skills in a wide range of technologies, from Flash animations to cutting-edge frameworks like React and Redux.</p>
            <p>My passion for providing exceptional solutions is complemented by my commitment to security in every project. My goal is to ensure client satisfaction by delivering aesthetically pleasing, robust, and secure web solutions. My meticulous focus on every detail and my ability to deliver quality results are the pillars of my professional work.</p>`
            },
          technologies: {
            phrase: 'Over the years, I have accumulated experience<br /> in multiple web technologies.'
          },
          curriculum: {
            work_experience: {
              title: 'WORK EXPERIENCE',
              type: 'experience',
              present: 'present',
              hexaware: {
                title: 'MICROSOFT FULL STACK DEVELOPER',
                at: 'at Hexaware Technologies / EY',
                location: 'Guadalajara / Home Office',
                content: `[Details under Non-Disclosure Agreement]`
              },
              hcl: {
                title: 'SENIOR WEB DEVELOPER',
                at: 'at HCL Technologies / AllState - Esurance',
                location: 'San Francisco & Zapopan',
                content: `Web Developer, responsible of layout, development and styling in the first phase.
                Developed React / Redux components, maintenance of private Node.
                js package; develope styles for components in SASS style sheet language
                for one and a half year, then changed to other product where I Develop
                Components and Styles for Re-brand version of a .NET MVC Web App.
                As of 2019, there was a team restructuring and moved to the Customer Flow Development Team, 
                in ESURANCE / AllState web application, where I maintained 
                Frontend and Backend in .NET. As Full Stack Developer, was responsible for new improvements, 
                maintaining, troubleshooting and support for web applications at Sales / Marketing area.
                Responsible for release and live site defects raised for CF in JIRA application.
                Collaborated on re-branding project in CF application for auto quotes.
                Drove responsive design implementation for old CF pages. Worked on performance enhancement for Customer Flow saving 1.5sec in average loading.
                Analyzed failed interactions and malicious activity on the web app production environment using Splunk and HotJar, 
                providing insights and reports, helping our team to react on time over those threats.`
              },
              puntoasterisco: {
                title: 'WEB DEVELOPER',
                at: 'at PUNTOASTERISCO S.C.',
                location: 'Chicago, Washington, Guadalajara & Zapopan',
                content: `Full Stack Web Developer, responsible of layout, development and updating of 
                dynamic web pages, developed in PHP, HTML 5, CSS 3, JavaScript, with Frameworks like Yii2, 
                Foundation, Bootstrap, jQuery, jQuery-UI, Ink and external libraries such as Font Awesome, 
                css Animate and pre-processed style sheets in SASS (SCSS), PHP CMS Development. 
                Technical Support for Email Accounts by telephone (Spanish). 
                Manager of a subordinate Frontend Web Developer.`
              },
              ggc: {
                title: 'WEB MASTER & MULTIMEDIA DESIGNER',
                at: 'at Grupo Gráfico Consultor S.C.',
                location: 'Guadalajara',
                content: `Daily and monthly update of webpages: rediseno.com, bazargrafico.com, imprentamexico.com, 
                design-fest.com and dpixeles.com. Brand and product presentation. Presentation of Media Kit and 
                various events. Web and Multimedia Production Director at international conferences Design Fest 2007. 
                Management of Audio visual equipment in the conference hall of TEC de Monterrey Campus Guadalajara. 
                Design and Animation Slides. Animation design and presentation of speakers and exhibitors. 
                Web design and coordination of the daily update registration by web. Web design, Banners and Pop-ups.`
              },
              freelance: {
                title: 'DESIGNER & WEB DEVELOPER',
                at: 'Freelance',
                location: 'San Francisco/remoto, Bombay/remoto, Los Mochis, Guadalajara & Zapopan',
                content: `Developed and maintained custom websites for various clients across different industries.
                Created visually appealing web designs tailored to each client's branding and specifications.
                Implemented responsive design principles to ensure optimal user experience across devices.
                Conducted regular website maintenance and updates to keep sites secure and up-to-date.
                Provided multimedia solutions including Flash animations and video editing services, 
                also corporate image and editorial design as well.`
              }
            },
            education: {
              title: 'EDUCATION',
              type: 'education',
              iteso_design: {
                title: 'DESIGN B.A.',
                at: 'at ITESO Jesuit University',
                location: 'Tlaquepaque',
                content: `Graphic and Industrial Design`,
                license: `Professional license: 5880448`
              },
              ivei_web_master: {
                title: 'WEB MASTER DIPLOMA COURSE',
                at: 'at IVEI',
                location: 'Guadalajara',
                content: `One year course of basic HTML5, CSS3, JavaScript, PHP, JAVA, .NET, MySQL`
              },
              google_analytics: {
                title: 'GOOGLE ANALYTICS CERTIFIED (EXPIRED)',
                at: 'at Google Academy',
                location: 'Online',
                validity: `Validity: 20 July 2015 - 20 October 2016`,
                license: `License: 109277918343153033229`
              },
              itszapopan_java: {
                title: 'JAVA TRAINING',
                at: 'at ITSZapopan',
                location: 'Zapopan',
                content: `Scholarship by Government of Zapopan, through the ICOE in its program 
                "Aquí hay Futuro" for JAVA Course, with a duration of 3 months.`
              },
              uncommon_concept_react: {
                title: 'REACT / REDUX TRAINING',
                at: 'By Uncommon Concept',
                location: 'Zapopan',
                content: `Training of 3 months gave to all Esurance Project Team in HCL Technologies, 
                for a new project that required this technology.`
              }
            },
            knowledge: {
              title: 'KNOWLEDGE',
              type: 'knowledge'
            },
            contact: {
              title: 'CONTACT INFO',
              type: 'contact'
            },
            skills: {
              title: 'SKILLS',
              type: 'skills'
            },
            languages: {
              title: 'LANGUAGES',
              type: 'languages',
              spanish: 'Spanish (1rst)',
              english: 'English',
              asterisc1: 'Are not considered programming languages, but are mark up languages.',
              asterisc2: 'Considered scripting language.'
            }
          },
          mes: {
            1: 'Jan',
            2: 'Feb',
            3: 'Mar',
            4: 'Apr',
            5: 'May',
            6: 'Jun',
            7: 'Jul',
            8: 'Aug',
            9: 'Sept',
            10: 'Oct',
            11: 'Nov',
            12: 'Dec',
          }
        }
      }
    }
  });

export default i18n;