import React from 'react';
import { Row, Col } from 'react-bootstrap';

function Skill(props) {
    return(
        <Row className="skill" data-aos="fade-right" data-aos-delay="500">
            <Col xs={6} className="skill-item">{props.name}</Col>
            <Col xs={6} className="skill-value" data-aos="fade-left" data-aos-delay="1000" data-aos-duration="1500">
                <span data-skill={props.value} />
            </Col>
        </Row>
    )
} 
    
Skill.defaultProps = {  
    value: 0  
} 

export default Skill;