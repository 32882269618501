import React, { useState } from 'react';
import { ReactComponent as Logo } from '../img/arturocanez.svg';
import { Container, Navbar, Nav, Form, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

function Header() { 
    const [expanded, setExpanded] = useState(false);

    const lngs = {
      es: { nativeName: 'Esp' },
      en: { nativeName: 'Eng' }
    };
    const { t, i18n } = useTranslation();
    return (
      <Navbar bg="dark" variant="dark" expand="md" expanded={expanded} fixed="top">
        <Container>
          <Navbar.Brand href={`#${t('nav_link.about')}`}><Logo id="logo_arturo_canez" className="logo" /></Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" className="flex flex-row-reverse" onClick={() => setExpanded(expanded ? false : "expanded")} />
          <Navbar.Collapse id="basic-navbar-nav" className="flex justify-content-end">
            
            <Nav className="text-center text-md-start" activeKey="">
              <Nav.Link href={`#${t('nav_link.about')}`} onClick={() => setExpanded(false)}>{t('nav.about')}</Nav.Link>
              <Nav.Link href={`#${t('nav_link.curriculum')}`} onClick={() => setExpanded(false)}>{t('nav.experience')}</Nav.Link>
              <Nav.Link href={`#${t('nav_link.contact')}`} onClick={() => setExpanded(false)}>{t('nav.contact')}</Nav.Link>
            </Nav>

            <Form className="text-center text-md-end">
                {Object.keys(lngs).map((lng) => (
                  <Button variant="secondary" key={lng} className={ i18n.resolvedLanguage === lng ? 'fw-bold' : 'fw-light' } type="submit" onClick={() => { i18n.changeLanguage(lng); setExpanded(false); }}>
                    {lngs[lng].nativeName}
                  </Button>
              ))}
            </Form>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    );
}
export default Header;
