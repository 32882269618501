import { Col } from 'react-bootstrap';
import CurriculumSectionTitle from './_curriculum_section_title';
import CurriculumContent from './_curriculum_content';
import { faBriefcase, faBookOpen, faBook } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';

function CurriculumMainContents() {
    const { t } = useTranslation();
    return (
        <Col md={8} className="curriculum-content">
            <CurriculumSectionTitle title={t('curriculum.work_experience.title')} id="experience" icon={faBriefcase} />
                                    
            <CurriculumContent 
                type={t('curriculum.work_experience.type')}
                //timeline={`2017 - ${t('curriculum.work_experience.present')}`}
                timeline={`${t('mes.3')} 2024 - ${t('present')}`}
                title={t('curriculum.work_experience.hexaware.title')}
                at={t('curriculum.work_experience.hexaware.at')}
                location={t('curriculum.work_experience.hexaware.location')}
                content={t('curriculum.work_experience.hexaware.content')}
            />
            
            <CurriculumContent 
                type={t('curriculum.work_experience.type')}
                //timeline={`2017 - ${t('curriculum.work_experience.present')}`}
                timeline={`${t('mes.4')} 2017 - ${t('mes.3')} 2023`}
                title={t('curriculum.work_experience.hcl.title')}
                at={t('curriculum.work_experience.hcl.at')}
                location={t('curriculum.work_experience.hcl.location')}
                content={t('curriculum.work_experience.hcl.content')}
            />

            <CurriculumContent 
                type={t('curriculum.work_experience.type')}
                timeline={`${t('mes.5')} 2011 - ${t('mes.4')} 2017`}
                title={t('curriculum.work_experience.puntoasterisco.title')}
                at={t('curriculum.work_experience.puntoasterisco.at')}
                location={t('curriculum.work_experience.puntoasterisco.location')}
                content={t('curriculum.work_experience.puntoasterisco.content')}
            />


            <CurriculumContent 
                type={t('curriculum.work_experience.type')}
                timeline="2007 - 2009"
                title={t('curriculum.work_experience.ggc.title')}
                at={t('curriculum.work_experience.ggc.at')}
                location={t('curriculum.work_experience.ggc.location')}
                content={t('curriculum.work_experience.ggc.content')}
            />

            <CurriculumContent 
                type={t('curriculum.work_experience.type')}
                timeline={[`${t('mes.3')} 2023 - ${t('mes.3')} 2024`,`${t('mes.8')} 2015 - ${t('mes.4')} 2017`,`${t('mes.4')} 2009 - ${t('mes.5')} 2011`,`${t('mes.6')} 2005 - ${t('mes.5')} 2007`]}
                title={t('curriculum.work_experience.freelance.title')}
                at={t('curriculum.work_experience.freelance.at')}
                location={t('curriculum.work_experience.freelance.location')}
                content={t('curriculum.work_experience.freelance.content')}
            />

            <CurriculumSectionTitle title={t('curriculum.education.title')} id={t('curriculum.education.type')} icon={faBookOpen} />

            <CurriculumContent 
                type={t('curriculum.education.type')}
                timeline="2001 - 2006"
                title={t('curriculum.education.iteso_design.title')}
                at={t('curriculum.education.iteso_design.at')}
                location={t('curriculum.education.iteso_design.location')}
                content={[t('curriculum.education.iteso_design.content'),
                t('curriculum.education.iteso_design.license')]}
            />

            <CurriculumContent 
                type={t('curriculum.education.type')}
                timeline="2011 - 2012"
                title={t('curriculum.education.ivei_web_master.title')}
                at={t('curriculum.education.ivei_web_master.at')}
                location={t('curriculum.education.ivei_web_master.location')}
                content={t('curriculum.education.ivei_web_master.content')}
            />

            <CurriculumContent 
                type={t('curriculum.education.type')}
                timeline="2015"
                title={t('curriculum.education.google_analytics.title')}
                at={t('curriculum.education.google_analytics.at')}
                location={t('curriculum.education.google_analytics.location')}
                content={[t('curriculum.education.google_analytics.validity'),
                t('curriculum.education.google_analytics.license')]}
                />

            <CurriculumContent 
                type={t('curriculum.education.type')}
                timeline="2018"
                title={t('curriculum.education.itszapopan_java.title')}
                at={t('curriculum.education.itszapopan_java.at')}
                location={t('curriculum.education.itszapopan_java.location')}
                content={t('curriculum.education.itszapopan_java.content')}
            />

            <CurriculumContent 
                type={t('curriculum.education.type')}
                timeline="2017"
                title={t('curriculum.education.uncommon_concept_react.title')}
                at={t('curriculum.education.uncommon_concept_react.at')}
                location={t('curriculum.education.uncommon_concept_react.location')}
                content={t('curriculum.education.uncommon_concept_react.content')}
            />

            <CurriculumSectionTitle title={t('curriculum.knowledge.title')} id={t('curriculum.knowledge.type')} icon={faBook} />

            <CurriculumContent 
                type={t('curriculum.knowledge.type')}
                content={`SSH, MySQL, HTML 4.1 / 5, XML, CSS 2.1 / 3, XML, JSX, JSon, Ajax, XAjax, SASS (SCSS), JavaScript, React, Redux, JQuery, JQuery-UI, 
                Zurb Foundation, Bootstrap, Zurb Ink, PHP, Yii2, JAVA, C#, ASP .NET MVC 4.0, NPM, NodeJS, GIT, JAVA, Yarn, Joomla, Grupal, Wordpress, SPLUNK, HotJar, Google Analytics, Agile Scrum Methodology.`}
            />

        </Col>
    );
}
export default CurriculumMainContents;