import {useState} from 'react';
import { ReactComponent as Logo } from '../img/arturocanez.svg';
// import { Col } from 'react-bootstrap';
// import { useTranslation } from 'react-i18next';

export default function LogoAnimation() {
    // const { t } = useTranslation();
    const [rotateIt, setRotateIt] = useState(false);

    const handleRotateClick = event => {
        // 👇️ toggle isActive state on click
        setRotateIt(current => !current);

        // 👇️ after animation toggle isActive state to false
        setTimeout(() => { setRotateIt(false) }, 801);
      };
    return (
        <section className="logo-animation">
            <Logo id="animated_logo"  className={ rotateIt ? 'rotation animate_once' : '' } onClick={handleRotateClick} />
        </section>
    );
}