import AOS from 'aos';
import { Header, Intro, Tecnologias, Curriculum, LogoAnimation, Footer } from './components';
import './components/i18n';
import './sass/app.scss';

function App() {
  const urlParams = new URLSearchParams(window.location.search);
  const print = urlParams.has('print');
  const noContact = urlParams.has('no-contact');
  return (
    <div className={`App ${(print ? 'print':'')} ${(noContact ? 'no-contact':'')}`}>
      <Header />
      <main>
        <Intro />
        <Tecnologias />
        <Curriculum />
        <LogoAnimation />
      </main>
      <Footer />
    </div>
  );
}

AOS.init();
export default App;
