import { Container, Row } from 'react-bootstrap';
import CurriculumMainContents from './_curriculum_main_contents'
import CurriculumAside from './_curriculum_aside';

function Curriculum() {
    return (
        <section id="curriculum" className='curriculum'>
            <Container fluid>
                
                <Row>
                    <CurriculumMainContents />
                    <CurriculumAside />
                </Row>
            </Container>
        </section>
    );
}
export default Curriculum;