import { Container, Row, Col } from 'react-bootstrap';
const actYear = new Date().getFullYear();

function Footer() {
    return (
        <Container fluid className="footer" >
            <Row>
                <Col xs={12} className="tit-nombre text-center"><small className="text-secondary">Arturo Cáñez González</small></Col>
                <Col className="pt-3 pb-3 copyright">
                    CáñezDesign &copy; {actYear}
                </Col>
            </Row>
        </Container>
    );
}

export default Footer;