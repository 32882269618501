export const logo = require('./arturocanez_wb.png');
export const arturo = require('./arturo3.png');

export const apache = require('./programming-language-logos/png-256/apache-256x256-1174973.png');
export const babel = require('./programming-language-logos/png-256/babel-256x256-1175262.png');
export const bitbucket = require('./programming-language-logos/png-256/bitbucket-256x256-1175256.png');
export const bootstrap = require('./programming-language-logos/png-256/bootstrap-256x256-1175203.png');
export const css3 = require('./programming-language-logos/png-256/css3-256x256-1175239.png');
export const debian = require('./programming-language-logos/png-256/debian-256x256-1175232.png');
export const csharp = require('./programming-language-logos/png-256/csharp-256x256-1175241.png');
export const docker = require('./programming-language-logos/png-256/docker-256x256-1175197.png');
export const drupal = require('./programming-language-logos/png-256/drupal-256x256-1175227.png');
export const git = require('./programming-language-logos/png-256/git-256x256-1175195.png');
export const grunt = require('./programming-language-logos/png-256/grunt-256x256-1174932.png');
export const html5 = require('./programming-language-logos/png-256/html5-256x256-1175193.png');
export const javascript = require('./programming-language-logos/png-256/javascript-256x256-1174949.png');
export const mysql = require('./programming-language-logos/png-256/mysql-256x256-1174941.png');
export const nodejs = require('./programming-language-logos/png-256/node-js-256x256-1174937.png');
export const ssh = require('./programming-language-logos/png-256/ssh-256x256-1174994.png');
export const typescript = require('./programming-language-logos/png-256/typescript-256x256-1174965.png');
export const visualstudio = require('./programming-language-logos/png-256/visualstudio-256x256-1174964.png');
export const yarn = require('./programming-language-logos/png-256/yarn-256x256-1174975.png');
export const npm = require('./programming-language-logos/png-256/npm-256x256-1982834.png');
export const php = require('./programming-language-logos/png-256/php-256x256-1982833.png');
export const react = require('./programming-language-logos/png-256/react-256x256-1982831.png');
export const python = require('./programming-language-logos/png-256/python-256x256-1982832.png');
export const sass = require('./programming-language-logos/sass.png');
//export const  = require('./programming-language-logos/png-256/.png');