import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLocationDot } from '@fortawesome/free-solid-svg-icons';

function CurriculumContent(props) {
    const hasAt = (props.at !== "");
    const hasTitle = (hasAt || props.title !== "");
    const hasLocation = (props.location !== "");

    const hasContent = (props.content !== "" || Array.isArray(props.content));
    let contentItems = "";
    if (hasContent) {
        if (typeof props.content === "string" || props.content instanceof String) {
            contentItems = <p>{props.content}</p>;
        } else {
            contentItems = props.content.map((c) => <p key={c}>{c}</p>);
        }
    }

    const hasTimeline = (props.timeline !== "" || Array.isArray(props.timeline));
    let timelineItems = "";
    if(hasTimeline) {
        if(typeof props.timeline === "string" || props.timeline instanceof String) {
            timelineItems = <p>{props.timeline}</p>;
        } else {
            timelineItems = props.timeline.map((t) => <div key={t}>{t}</div>);
        }
    }

    return (
        <Row className={props.type}>
            <Col sm={2} className="timeline" data-aos="zoom-in-left" data-aos-delay="300">
                {timelineItems}
            </Col>
            <Col sm={10} className={props.type+"-content"} data-aos="flip-right" data-aos-delay="500">
                { hasTitle
                   ? <h4 className="pb-2"> {props.title} { hasAt 
                        ? <span key={props.at}>{props.at}</span>
                        : null 
                    }</h4>
                    : null
                }

                { hasLocation 
                    ? <Row md={{ span: 6, offset: 6}} className="text-end"><small><FontAwesomeIcon icon={faLocationDot} /> {props.location}</small></Row> 
                    : null 
                }
                { hasContent
                    ? <div className={props.type+"-text"}>{contentItems}</div>
                    : null
                }
            </Col>
        </Row>
    );
}
    
CurriculumContent.defaultProps = {
    title: "",
    timeline: "",
    type: "experience",
    at: "",
    location: "",
    content: "" 
} 

export default CurriculumContent;