import { Container, Row, Col } from 'react-bootstrap';
import { useTranslation, Trans } from 'react-i18next';
import { arturo } from '../img'

function Intro() {
  const { t } = useTranslation();
  const urlParams = new URLSearchParams(window.location.search);
  const print = urlParams.has('print');
  return (
      <section id={t('about.type')} className="head-container">
      <Container>
        <Row>
          <Col md={print ? 12 : 9} className="text-white" data-aos="fade-left" data-aos-delay="500">
            <h1 className={`mt-5 text-center ${print ? 'd-none':''}`}>{t('about.title')}<br /><small><em>{t('about.title2')}</em></small></h1>
            <div className={`mt-${print ? '5':'3'} text-start`}>
              <Trans i18nKey="about.content">
                <p>Hola mi nombre es Arturo Cáñez, soy Licenciado en diseño integral egresado en 2006 por la Universidad Jesuita ITESO en Guadalajara (Jalisco, México); 
                  comencé a trabajar haciendo animaciones en Flash y creando páginas web interactivas en ésa tecnología, al ver que mi trabajo se enfocaba en ésa dirección 
                  comencé a especializarme en Diseño Web.</p>
              </Trans>
            </div>
          </Col>
          <Col md={3} className={`arturo-img d-none ${print ? '':'d-md-block'}`} data-aos="fade-right" data-aos-delay="500">
            <img id="img_arturo" src={arturo} alt="Lic. Arturo Cáñez González" />
          </Col>
        </Row>
      </Container>
    </section>
  );
}
  
  export default Intro;