import { Col } from 'react-bootstrap';
import CurriculumSectionTitle from './_curriculum_section_title';
import Skill from './_skill';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBook, faEnvelope, faPhone, faChartLine, faLanguage, faFilePdf, faFileDownload } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';

function CurriculumAside() {
    const { t } = useTranslation();
    return (
        <Col md={4} className="aside">
            <div className="d-flex flex-column">
            {/* Personal Data */}
            <div id={t('curriculum.contact.type')} className="order-3 order-md-1">
                <CurriculumSectionTitle type="aside" title={t('curriculum.contact.title')} icon={faBook} breakpoint={false} />

                <ul data-aos="flip-left">
                    <li><strong>Arturo Cáñez González</strong></li>
                    <li><a href="mailto:arturo@canezdesign.com" target="_blank" rel="noreferrer" ><FontAwesomeIcon icon={faEnvelope} color="black"/> arturo@canezdesign.com</a></li>
                    <li><a href="tel:+523338149931" target="_parent"><FontAwesomeIcon icon={faPhone} color="black"/> +52 33 3814 9931</a></li>
                    <li><a href={`pdf/Arturo_Canez_${t('lang.ISO_639_1')}`} target="_blank" rel="noreferrer noopener"><FontAwesomeIcon icon={faFilePdf} color="black"/> {t('nav.pdf_download')} <FontAwesomeIcon icon={faFileDownload}color="gray"/></a></li>
                </ul>
            </div>
            {/* Skills */}
            <div id={t('curriculum.skills.type')} className="order-1 order-md-2">
                <CurriculumSectionTitle type="aside" title={t('curriculum.skills.title')} icon={faChartLine} breakpoint={false} />
                <div className="pb-4">
                    <Skill name="MS Excel" value="3" />
                    <Skill name="MS Word" value="4" />
                    <Skill name="MS PowerPoint" value="5" />
                    <br/>
                    <Skill name="Adobe Illustrator" value="4.5" />
                    <Skill name="Adobe PhotoShop" value="4.5" />
                    <Skill name="Adobe InDesign" value="4" />
                    <Skill name="Gimp" value="4" />
                    <br />
                    <Skill name="Visual Studio" value="4" />
                    <Skill name="Visual Studio Code" value="4.5" />
                    <Skill name="NetBeans" value="4" />
                    <Skill name="Sublime Text" value="4" />
                    <Skill name="NotePad ++" value="4.5" />
                    <Skill name="DB Designer" value="3" />
                    <Skill name="MySQL Workbench" value="3" />
                    <Skill name="MySQL Query Browser" value="4" />
                    <Skill name="FileZilla" value="5" />
                    <Skill name="PuTTY" value="5" />
                    <Skill name="Fire SSH" value="5" />
                    <Skill name="Secure CRT" value="4" />
                    <br />
                    <Skill name="Apache2 HTTP Server" value="4" />
                    <Skill name="NodeJs" value="3.5" />
                    <Skill name="NPM" value="4.5" />
                    <Skill name="Bower" value="4" />
                    <Skill name="Composer" value="3" />
                    <Skill name="Docker" value="2" />
                    <br />
                    <Skill name="GIT" value="4" />
                    <Skill name="TFS" value="4" />
                    <Skill name="Tortoise Mercurial" value="4" />
                    <Skill name="BitBucket" value="4" />
                    <br />
                    <Skill name="Jira" value="4.5" />
                    <Skill name="Confluence" value="5" />
                    <Skill name="Splunk" value="4" />
                    <br />
                    <Skill name="Agile Scrum" value="4.5" />
                </div>
            </div>
            {/* Languages */}
            <div id={t('curriculum.languages.type')} className="order-2 order-md-3">
                <CurriculumSectionTitle type="aside" title={t('curriculum.languages.title')} icon={faLanguage} breakpoint={false} />
                <div>
                    <Skill name={t('curriculum.languages.spanish')} value="5" />
                    <Skill name={t('curriculum.languages.english')} value="4" />
                    <br />
                    <Skill name="JavaScript" value="4.5" />
                    <Skill name="Python" value="3" />
                    <Skill name="PHP" value="3.5" />
                    <Skill name="JAVA" value="3" />
                    <Skill name="C#" value="3.5" />
                    <Skill name="SQL" value="3.5" />
                    <Skill name="TypeScript" value="2.5" />
                    <Skill name="Bash" value="3" />
                    <Skill name="Unix Shell" value="3" />
                    <Skill name="Batch" value="3" />
                    <br />
                    <Skill name="HTML *" value="5" />
                    <Skill name="CSS *" value="5" />
                    <Skill name="XML *" value="5" />
                    <Skill name="SVG *" value="4.5" />
                    <br />
                    <Skill name="SassScript **" value="4.5" />
                    <br />
                    <div data-aos="flip-down">
                        <p><small className="fw-light fst-italic"><strong>*</strong> {t('curriculum.languages.asterisc1')}</small></p>
                        <p><small className="fw-light fst-italic"><strong>**</strong> {t('curriculum.languages.asterisc2')}</small></p>
                    </div>
                </div>
            </div>
            </div>
        </Col>
    );
}
export default CurriculumAside;