import React from 'react';
import PropTypes from 'prop-types';
import { Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBriefcase } from '@fortawesome/free-solid-svg-icons';
function CurriculumSectionTitle(props) {
    let breakpointProps = {};
    if (props.breakpoint === true) {
        breakpointProps = {
            md: { span: 10, offset: 2 }
        }
    } 

    let isAside=(props.type === 'aside');

    return (
        <Col id={props.id} className={props.type+"-section"} data-aos="flip-up" {...breakpointProps}>
            <h3 className={"text-center" + (isAside ? "" : " text-md-start") }>
                <FontAwesomeIcon icon={props.icon} color="black"/> {props.title}
            </h3>
        </Col>
    );
}

CurriculumSectionTitle.propTypes = {  
    title: PropTypes.string.isRequired,
    id: PropTypes.string,  
    icon: PropTypes.object,
    breakpoint: PropTypes.bool,
    type: PropTypes.string
}  
    
CurriculumSectionTitle.defaultProps = { 
    icon: faBriefcase,
    breakpoint: true,
    type: "curriculum"
}

export default CurriculumSectionTitle;
