import { Container, Row, Col } from 'react-bootstrap';
import { useTranslation, Trans } from 'react-i18next';
import {
    logo,
    apache,
    css3,
    html5,
    react,
    javascript,
    bootstrap,
    typescript,
    babel,
    git,
    grunt,
    debian,
    csharp,
    php,
    mysql,
    python,
    sass
  } from '../img';

function Tecnologias() {
  const { t } = useTranslation();
  return (
      <section className='galaxy'>
        <Container fluid className="tecnologias text-white text-center">
          <Row>
            <Col data-aos="fade-top" data-aos-delay="1300">
              <h2 className="pt-5 pb-5 mt-5 mb-5">
                <Trans i18nKey="technologies.phrase" t={t}>
                  A lo largo de los años, he acumulado experiencia<br /> en multiples tecnologías web.
                </Trans>
              </h2>
            </Col>
          </Row>
          <img src={html5} alt="HTML5" />
          <img src={css3} alt="CSS3" />
          <img src={csharp} alt="C#" />
          <img src={mysql} alt="MySQL" />
          <img src={react} alt="React" />
          <img src={python} alt="Python" />
          <img src={babel} alt="Babel" />
          <img src={apache} alt="apache" />
          <img src={git} alt="Git" />
          <img src={javascript} alt="JavaScript" />
          <img src={bootstrap} alt="Bootstrap" />
          <img src={grunt} alt="Grunt" />
          <img src={typescript} alt="TypeScript" />
          <img src={php} alt="PHP" />
          <img src={debian} alt="Debian" />
          <img src={logo} alt="Arturo Cáñez" />
          <img src={sass} alt="Sass" />
        </Container>
      </section>
  );
}
export default Tecnologias;